export default {
  // PRODUCT
  AppProduct: '/product',
  AppProductDesignPWA: '/design-pwa',
  AppProductMagento2core: '/magento-core',
  AppProductAutomation: '/automation',
  AppMarketplaceIntegrations: '/marketplace-integrations',
  AppMarketingIntegration: '/marketing-integrations',
  AppErpIntegrations: '/erp-integrations',
  AppProductIntegrations: '/integrations',
  AppProductProcess: '/process',
  AppProductHosting: '/hosting',
  AppHostingArhitecture: '/hosting-arhitecture',
  AppWeMonitorEverything: '/we-monitor-everything',
  AppSecurityFirst: '/security-first',
  AppProductGdprDataGovernanace: '/gdpr-data-governance',
  // PRODUCT - END
  // SWITCHING
  AppSwitchingToZento: '/switching-to-zento',
  AppProcessForSwitching: '/process-for-switching',
  AppReasonsForSwitching: '/reasons-for-switching',
  // SWITCHING - END
  // WIKI
  AppWiki: '/wiki',
  AppWikiDesignGeneralDesignAndUx: '/wiki/design-and-ux/general-design',
  AppWikiDesignVision: '/wiki/design-and-ux/design-vision',
  AppWikiHostingArchitecture: '/wiki/hosting/architecture',
  AppWikiHostingM2kubernetes: '/wiki/hosting/m2-kubernetes',
  AppWikiHostingPwalambda: '/wiki/hosting/pwa-lambda',
  AppWikiHostingSecurity: '/wiki/hosting/security',
  AppWikiHostingStorageDatabase: '/wiki/hosting/storage-and-database',
  AppWikiHostingWhatiscloudhosting: '/wiki/hosting/what-is-cloud-hosting',
  AppWikiOverviewPwa: '/wiki/overview/what-is-pwa',
  AppWikiOverviewTco: '/wiki/overview/total-cost-of-ownership',
  AppWikiOverviewWhyzento: '/wiki/overview/why-zento',
  // WIKI - END
  AppPriceSlider: '/pricing',
  AppDataProtectionAndPrivacy: '/data-protection-and-privacy',
  AppPreContractualTerms: '/pre-contractual-terms',
  // ABOUT
  AppAbout: '/about',
  AppLeadershipTeam: '/leadership-team',
  // ABOUT - END
  AppContactPage: '/kontakt',
  AppBlog: '/blog',
  AppBlogArticle: '/blog/article',
  // PARTNER
  AppPartner: '/partner',
  AppAffiliate: '/affiliate',
  AppZentoCertifiedAffiliateFaq: '/zento-certified-affiliate-faq',
  AppBecomePartner: '/become-a-partner',
  AppZentoCertifiedBusinessPartnerFaq: '/zento-certified-business-partner-faq',
  AppFindPartner: '/find-a-partner',
  // PARTNER - END
  // CAREERS
  AppCareers: '/careers',
  AppCustomerSupportSpecialist: '/customer-support-specialist',
  AppCustomerSupportSpecialistPL: '/customer-support-specialist-pl',
  AppMagento2Develeper: '/senior-magento2-developer',
  AppMarketingSpecialist: '/content-marketing-specialist',
  AppQaSpecialist: '/qa-specialist',
  AppSalesReprezentative: '/sales-representative',
  AppSeniorReactDeveloper: '/senior-react-developer',
  AppWebDesigner: '/web-designer',
  // CAREERS - END
  // AWS
  AppAwsmkpSubscribe: '/awsmkp-subscribe',
  // AWS - END
  // LPS
  AppLps: '/lp',
  AppLPM1M2: '/lp/upgrade-magento1-to-magento2',
  // LPS - END
};
